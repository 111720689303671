import $ from 'jquery'
const $doc = $(document)

function customHeaderMenu(el) {
  if ($(el).attr('menuid')) {
    var menuId = "#" + $(el).attr('menuid');
    if ($(menuId).length) {
      toggleMenus(menuId);
    }
  }
}

function toggleMenus(menuId) {
  $(".nav-drop").removeClass('visible');
  $(menuId).addClass('visible');
}

function hideMenus(menuVisible, el) {
  if (menuVisible) $(el).addClass('visible');
  else $(".nav-drop").removeClass('visible');
}

export default function initCustomHeader() {
  window.onmouseover = function(e) {
    var menuParent = $(e.target).parents('.nav-drop');
    var linkParent = $(e.target).parents('.nav-drop__list-item');
    if (e.target.className === 'nav-list__link' || $(linkParent).length) {
      customHeaderMenu(e.target);
      return;
    } else if (e.target.className === 'nav-drop' || $(menuParent).length) {
      hideMenus(true, e.target);
      return;
    } else if (e.target.className != 'nav-drop' || !$(menuParent).length) {
      hideMenus(false, e.target);
      return;
    }
  };

}
