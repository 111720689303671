import $ from 'jquery'

function checkPdfLinks(){
  var links = $('a');
  $(links).each(function( index ) {
    if( $(this).attr('href').indexOf(".pdf") != -1){
      $(this).attr('target', '_blank');
    }
  });
}

export default function initPdfLinks(){
  $(document).ready( checkPdfLinks )
}
