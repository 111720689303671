import $ from 'jquery'

function init(){
  var circleSlider = $('.js-circle-gallery')
  if (circleSlider.length) {
    circleSlider.slick({
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"><i class="icon left-arrow"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="icon right-arrow"></i></button>',
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    })
  }
}

export default function initCircleGallery(){
  $(document).ready( init )
}
