import $ from 'jquery'

const $doc = $(document)
const $searchBar = $('.js-search-bar')
const $searchInput = $('.js-search-input')
const $searchButton = $('.js-header-search-button')
const $searchCloseButton = $('.js-header-search-close')

function showSearchBar() {
  $searchBar.addClass('is-active')
  setTimeout(() => $searchInput[0].focus(), 500)
}

function hideSearchBar() {
  $searchBar.removeClass('is-active')
}

export default function initHeaderSearch() {
  $searchButton.click(() => showSearchBar())
  $searchCloseButton.click(() => hideSearchBar())
}
