import $ from 'jquery'
const  $doc = $(document)

function copyUrl(){
  var dummy = document.createElement('input'),
  text = window.location.href;

  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

export default function initPostLinkCopy(){
  $doc.on('click', '.js-copy-link', copyUrl );
}
