import $ from 'jquery'

const body = $('body')
const page = $('.js-projects')
const submitButton = $('.js-form-submit')
const pressForm = $('.js-project-filters-form')
const resultsContainer = $('.js-projects-results')
const searchInput = $('.js-search-input')
const resetButton = $('.js-form-reset')
const selectBoxes = pressForm.find('select')
const loadMoreButton = $('.js-load-more')

let state = {
  searchActive: false,
  currentPage: 1,
};

function submitForm(loadMore = false) {
  // Converting form data to object
  const formData = pressForm.serialize().split("&");
  let formDataObj = {};
  for (const key in formData) {
    formDataObj[formData[key].split("=")[0]] = formData[key].split("=")[1];
  }

  // Set current page to 1 if this is not a load more request
  if (!loadMore) state.currentPage = 1

  // Send ajax request
  $.ajax({
    url: ajaxVariables.url,
    type: 'POST',
    data: {
      action: 'projects_filter',
      ...formDataObj,
      current_page: state.currentPage
    },
    beforeSend: function () {
      body.addClass("loading")
    },
    success: function (response) {
      if (loadMore) {
        resultsContainer.append(response)
        if($(".alert.alert--info").length) $(".js-load-more").hide();
      } else {
        resultsContainer.html(response)
        $(".js-load-more").show();
      }
      body.removeClass("loading")
      updateUrl(pressForm)
      state.searchActive = !checkFormIsEmpty(formDataObj)
      handleResetButton()
    },
    error: function (request, status, error) {
      body.css("cursor", "default")
    }
  })

}

function updateUrl() {
  const params = new URLSearchParams(pressForm.serialize())
  window.history.replaceState({}, '', `${location.pathname}#${params}`);
}

function handleEnter(e) {
  if (e.keyCode === 13 ) {
    submitForm()
  }
}

function handleSearch() {
  if (searchInput.val().length) {
    submitForm()
  }
}

function handleResetButton() {
  resetButton.css('display', state.searchActive ? 'block' : 'none')
}

function resetForm() {
  pressForm[0].reset()
  state.searchActive = false
  handleResetButton()
  selectBoxes.trigger('change')
  submitForm()
}

function checkFormIsEmpty(formData) {
  return !Object.values(formData).some(x => x !== null && x !== '')
}

function loadMore() {
  state.currentPage++
  submitForm(true)
}

function handleFirstLoad() {
  if (location.hash) {
    const params = new URLSearchParams(location.hash.substring(1))

    pressForm.find('input, select').each(function () {
      const name = $(this).attr('name')
      if (params.get(name)) {
        $(this).val(params.get(name))
      }
    })
    selectBoxes.trigger('change')
  }
}

export default function initProjectsFilters() {
  if (!page.length) return;

  $(document).on('ready', handleFirstLoad)
  submitButton.click(() => submitForm())
  $(document).on('keydown', '.js-search-input', handleEnter)
  resetButton.click(() => resetForm());
  selectBoxes.change(() => submitForm())
  loadMoreButton.click(() => loadMore());
  pressForm.submit((e) => {
    e.preventDefault()
    handleSearch()
  });
}
