import $ from 'jquery'

function init(){
  $(".wysiwyg-editor .gallery").addClass("js-post-gallery");
  initSlider()
}

function initSlider(){
  var postSlider = $('.js-post-gallery')
  if (postSlider.length) {
    postSlider.slick({
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"><i class="icon thin-left-arrow"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="icon thin-right-arrow"></i></button>',
    })
  }
}

export default function initPostGallery(){
  $(document).ready( init )
}
