import $ from 'jquery'
import 'select2'

export default function initSelect2(){
  $(document).ready( function() {
    // Get the first option of select boxes and set it as placeholder
    $('.custom-select, .gfield_select').each(function() {
      const placeholderText = $(this)[0][0].innerText;
      $(this).select2({
        placeholder: placeholderText,
        width: '100%',
        allowClear: true,
        minimumResultsForSearch: -1
      });
    }).on('select2:close', function(e) {
      const activeSelect = $(e.currentTarget);
      if ( activeSelect.val() !== '' ) {
        $(this).next().addClass('has-value');
      } else {
        $(this).next().removeClass('has-value');
      }
    });

  });
}
